import React, {useRef, useState} from "react";
import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingFocusManager,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useId,
    useInteractions,
    useRole
} from "@floating-ui/react";
import {Source} from "@/types/Source";
import NextImageWrapper from "@/components/NextImageWrapper";

export type Item = {
    name: string,
    image: string,
    amount: number,
    sources?: Source[]
}

type ItemAmountProps = Item & {
    grid?: boolean
}

export default function ItemAmount({name, image, amount, sources, grid}: ItemAmountProps) {
    const [isOpen, setIsOpen] = useState(false)
    const headingId = useId();
    const arrowRef = useRef(null);
    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            arrow({element: arrowRef}),
            offset(10),
            flip({fallbackAxisSideDirection: "end"}),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
        placement: "top"
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const {getReferenceProps, getFloatingProps} = useInteractions([
        click,
        dismiss,
        role
    ]);

    return (
        <div
            className={`flex flex-col items-center justify-center ${grid ? "" : "w-1/5"} flex-initial ${(sources && sources.length > 0) ? "hover:bg-proj-dark rounded-lg transition-all cursor-pointer" : ""}`}
            ref={refs.setReference} {...getReferenceProps()}>
            <NextImageWrapper alt={name} src={image} className="w-20 h-20"/>
            <h4 className="font-catamaran font-bold text-xl">{amount < 10 ? "0" : ""}{amount.toLocaleString()}</h4>

            {(isOpen && sources && sources.length > 0) && <FloatingFocusManager context={context} modal={false}>
				<div
					className="Popover bg-proj-dark rounded-lg no-focus-outline p-4 focus:outline-none flex flex-col"
					ref={refs.setFloating}
					style={floatingStyles}
					aria-labelledby={headingId}
                    {...getFloatingProps()}
				>
					<FloatingArrow context={context} ref={arrowRef}
								   className="[&>path:first-of-type]:stroke-proj-dark [&>path:last-of-type]:stroke-proj-dark fill-proj-dark"/>
                    {sources?.map((source, index) => {
                        return (
                            <a className="flex flex-row gap-2 items-center focus:outline-none"
                               href={source.url} target="_blank" key={index}>
                                {source.icon}
                                <h2 id={headingId}
                                    className="text-xl font-catamaran font-bold underline underline-offset-2">
                                    {source.name}
                                </h2>
                            </a>
                        )
                    })}
				</div>
			</FloatingFocusManager>}
        </div>
    );
}
