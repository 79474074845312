import {useState} from "react";
import Twemoji from 'react-twemoji';
import {SiInstagram, SiX, SiYoutube} from "@icons-pack/react-simple-icons";
import Link from "next/link";
import {Map, Menu, Triangle} from "lucide-react";
import ItemAmount from "@/components/ItemAmount";
import ItemAmountGroup from "@/components/ItemAmountGroup";
import TSIDisplay from "@/components/TSIDisplay";
import {Waypoint} from "react-waypoint";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import NextImageWrapper from "@/components/NextImageWrapper";

import PyroElement from "../../public/vectors/elements/pyro.svg"
import HydroElement from "../../public/vectors/elements/hydro.svg"
import AnemoElement from "../../public/vectors/elements/anemo.svg"
import ElectroElement from "../../public/vectors/elements/electro.svg"
import DendroElement from "../../public/vectors/elements/dendro.svg"
import CryoElement from "../../public/vectors/elements/cryo.svg"
import GeoElement from "../../public/vectors/elements/geo.svg"

import FivePointStar from "../../public/vectors/icons/5-point-star.svg"

export default function Home() {
    const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(false)
    const [isAtTop, setIsAtTop] = useState(true);

    const [activeSection, setActiveSection] = useState<number>(0)

    const onSectionChange = (section: number) => {
        setActiveSection(section)
        console.log(section)
    }

    return (
        <main className="bg-proj-bg" id="home"> {/* ANCHOR: #home */}
            {/* Top of the page Waypoint*/}
            <div className="absolute top-px">
                <Waypoint onLeave={() => {
                    setIsAtTop(false)
                }} onEnter={() => {
                    setIsAtTop(true)
                }}/>
            </div>

            {/* Main Splash waypoint */}
            <div className="absolute top-96">
                <Waypoint onLeave={() => {
                    setIsNavCollapsed(false)
                }} onEnter={() => {
                    setIsNavCollapsed(true)
                    onSectionChange(0)
                }}/>
            </div>

            {/* Bottom Bar */}
            <div
                className={`flex justify-center fixed w-[100vw] px-16 z-50 flex-row gap-5 items-end ${isAtTop ? "bottom-16" : "bottom-0"} transition-all duration-700`}>
                <div className="rounded-full h-1 flex-grow bg-cyan-500"/>
                <NextImageWrapper alt="Clorinde wish image" src="/images/4-point-star.png"
                                  className="h-10 w-10 translate-y-[50%] translate-x-[50%]"/>
            </div>

            {/* Navigation */}
            <div
                className={`fixed top-8 left-8 z-40 ${isNavCollapsed ? "w-0 h-0" : "w-72 h-48"} bg-proj-dark rounded-lg flex flex-row overflow-hidden p-4 gap-2 items-center transition-all`}>
                <div
                    className="fixed top-8 left-8 flex items-center justify-center bg-proj-dark p-2 rounded-lg z-50 cursor-pointer">
                    <Menu className="" onClick={() => {
                        setIsNavCollapsed(!isNavCollapsed)
                    }}/>
                </div>
                <Triangle className="rotate-90 fill-[#75aaac] stroke-[#75aaac]"/>
                <div className="w-full h-full flex flex-col transition-all" style={{
                    transform: `translateY(calc(50% - 12px - ${28 * activeSection}px))`
                }}>
                    <AnchorLink
                        className={`font-catamaran font-extrabold text-xl ${activeSection == 0 ? "text-[#75aaac]" : ""}`}
                        href="#home"
                    >
                        HOME
                    </AnchorLink>
                    <AnchorLink
                        className={`font-catamaran font-extrabold text-xl ${activeSection == 1 ? "text-[#75aaac]" : ""}`}
                        href="#biography-details"
                    >
                        BIOGRAPHY DETAILS
                    </AnchorLink>
                    <AnchorLink
                        className={`font-catamaran font-extrabold text-xl ${activeSection == 2 ? "text-[#75aaac]" : ""}`}
                        href="#quick-glance"
                    >
                        QUICK GLANCE
                    </AnchorLink>
                    <a className="font-catamaran font-extrabold text-xl">TEST1</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST2</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST3</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST4</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST5</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST6</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST7</a>
                    <a className="font-catamaran font-extrabold text-xl">TEST8</a>
                </div>
            </div>


            <div className="flex flex-col p-16 h-[100vh-64px]">
                {/* Section 1 */}
                <div className="relative flex flex-col h-[calc(100vh-128px)] justify-center">
                    {/* TODO: Optimize using next/image */}
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img alt="Clorinde wish image" src="/images/clorinde_wish.png"
                         className="absolute top-0 right-0 h-[calc(100vh-128px)] select-none"/>

                    {/* Side Bar */}
                    <div className="flex items-center justify-center absolute right-0 h-full">
                        <div className="rounded-full h-[80%] w-1 bg-cyan-500"/>
                    </div>

                    {/* Header */}
                    <div className="flex flex-row items-center justify-center absolute top-0 left-0 gap-8">
                        <h1 className="text-4xl font-catamaran font-bold z-20">GENSHIN CHARACTER GUIDE</h1>
                        <h1 className="text-xl font-catamaran font-semibold">
                            LYNMAN ALPHA
                        </h1>
                    </div>

                    {/* Center Section */}
                    <div className="flex flex-col">
                        <div className="flex flex-row items-end gap-14">
                            <div className="flex flex-row gap-4 -translate-x-2 -translate-y-5">
                                <FivePointStar alt="Star" className="w-6 h-6 select-none fill-proj-star-lit"/>
                                <FivePointStar alt="Star" className="w-6 h-6 select-none fill-proj-star-lit"/>
                                <FivePointStar alt="Star" className="w-6 h-6 select-none fill-proj-star-lit"/>
                                <FivePointStar alt="Star" className="w-6 h-6 select-none fill-proj-star-lit"/>
                                <FivePointStar alt="Star" className="w-6 h-6 select-none fill-proj-star-lit"/>
                            </div>
                            <NextImageWrapper alt="Fontaine" src="/images/regions/fontaine.png"
                                              className="h-24 opacity-50 select-none w-44"/>
                        </div>
                        <h1 className="text-8xl font-catamaran font-black z-10">CLORINDE</h1>
                        <p className="font-catamaran italic w-[35%] z-10 text-justify">
                            If we wanted to kill the prey in one strike, we would need a powerful implement.
                            However, that also goes for prey that must be captured and safely contained.
                            Only a hunter who is a true expert at subduing their prey can snare it without harming it.
                            The line that divides life and death is often exceedingly thin.
                        </p>
                    </div>

                    {/* Element Section */}
                    <div
                        className={`flex flex-row gap-2 absolute left-0 ${isAtTop ? "bottom-16" : "bottom-0"} transition-all duration-700`}>
                        <PyroElement alt="Pyro" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                        <HydroElement alt="Hydro" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                        <AnemoElement alt="Anemo" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                        <ElectroElement alt="Electro" className="w-6 h-6 select-none fill-proj-element-electro"/>
                        <DendroElement alt="Dendro" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                        <CryoElement alt="Cryo" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                        <GeoElement alt="Geo" className="w-6 h-6 select-none fill-proj-element-unlit"/>
                    </div>
                </div>

                <div className="h-16"/>

                {/* Section 2 */}
                <div className="flex flex-row gap-8">
                    <div className="rounded-lg bg-proj-box p-4">
                        <p className="font-catamaran text-justify">
                            The long-awaited Champion Duelists in Fontaine marks her debut as a playable character on
                            this version! <span className="text-gi-electro">Clorinde</span> works alongside Neuvillette,
                            allowing the accused to defend their honor through combat, gaining her nickname Champion
                            Duelist. She is a woman with principle, allowing her skillset (learned through the
                            Marechaussee Hunters) to be used under Fontainian Law. She was introduced in the game
                            last <span className="font-extrabold">June 5, 2024</span>.
                        </p>
                    </div>
                    <div className="rounded-lg bg-proj-box p-4">
                        <p className="font-catamaran italic text-justify">
                            Greetings. I am Clorinde, a Champion Duelist of the Court of Fontaine. If you wish to hire
                            my services, please state the nature of your dispute... You may leave out any extraneous
                            details, those are no concern of mine.
                        </p>
                    </div>
                </div>

                <div className="h-16"/>

                <div className="flex justify-center px-16 flex-row gap-5 items-end h-10">
                    <NextImageWrapper alt="Clorinde wish image" src="/images/4-point-star.png"
                                      className="h-10 w-10"/>

                    <div className="rounded-full h-1 flex-grow bg-cyan-500 -translate-y-[1.1rem]"/>

                    <NextImageWrapper alt="Clorinde wish image" src="/images/4-point-star.png"
                                      className="h-10 w-10"/>
                </div>

                <div className="h-16" id="biography-details"/>
                {/* ANCHOR: #biography-details */}

                {/* Section 3 */}
                <div className="flex flex-col gap-8 relative">
                    <h2 className="font-catamaran text-5xl font-extrabold">Biography Details</h2>

                    <div className="absolute top-0">
                        <Waypoint onEnter={() => {
                            onSectionChange(1)
                        }} bottomOffset="50%"/>
                    </div>

                    <div className="flex flex-row gap-8">
                        <div className="flex rounded-lg bg-proj-box p-4 flex-col items-center w-96 gap-4">
                            <div className="flex w-full">
                                <h1 className="text-2xl font-catamaran font-extrabold">BASIC INFORMATION</h1>
                            </div>
                            <div className="flex flex-col items-center">
                                <h3 className="font-catamaran font-semibold">Birthday</h3>
                                <h4 className="font-catamaran">September 20th</h4>
                            </div>
                            <div className="flex flex-row justify-center items-center gap-5">
                                <div className="flex flex-col items-center justify-start w-36">
                                    <div
                                        className="flex items-center justify-center w-16 h-16 rounded-full bg-proj-dark select-none">
                                        <NextImageWrapper className="h-14 w-14" src="/images/icons/weapons/sword.png"
                                                          alt="Sword"/>
                                    </div>

                                    <div className="h-2"/>

                                    <h3 className="font-catamaran font-semibold">Weapon</h3>
                                    <h4 className="font-catamaran">Sword</h4>
                                </div>
                                <div className="flex flex-col items-center justify-start w-36">
                                    <div
                                        className="flex items-center justify-center w-16 h-16 rounded-full bg-proj-dark select-none">
                                        <NextImageWrapper className="h-14 w-14"
                                                          src="/images/items/food/tagged-and-bagged.png"
                                                          alt="Tagged and Bagged"/>
                                    </div>

                                    <div className="h-2"/>

                                    <h3 className="font-catamaran font-semibold">Special Dish</h3>
                                    <h4 className="font-catamaran">Tagged and Bagged</h4>
                                </div>
                            </div>
                            <div className="flex flex-col items-center justify-start gap-2">
                                <div className="flex flex-row gap-5 items-center justify-center">
                                    <h3 className="font-catamaran font-semibold">Vision</h3>
                                    <div className="flex flex-row gap-1 items-center justify-center ">
                                        <NextImageWrapper alt="Electro" src="/images/elements/electro-lit.png"
                                                          className="h-6 w-6 select-none"/> {/* Selected Element */}

                                        <h4 className="font-catamaran text-gi-electro">Electro</h4>
                                    </div>
                                </div>
                                <div className="flex flex-row gap-5 items-center justify-center">
                                    <h3 className="font-catamaran font-semibold">Rarity</h3>
                                    <div className="flex flex-row gap-1 items-center justify-center">
                                        <NextImageWrapper alt="Star" src="/images/gi-5-stars.png"
                                                          className="h-6 w-6 select-none"/>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col items-center">
                                <h3 className="font-catamaran font-semibold">Affiliations</h3>
                                <h4 className="font-catamaran">Trial Court</h4>
                            </div>
                        </div>
                        <div
                            className="flex flex-col rounded-lg bg-proj-box p-4 flex-grow items-center justify-center relative">
                            <h1 className="text-2xl font-catamaran font-extrabold absolute left-4 top-4">VOICE
                                ACTORS</h1>
                            <div className="flex flex-row items-center justify-center gap-8">
                                <div className="flex flex-col items-center justify-start w-36">
                                    <NextImageWrapper className="w-36 h-36 rounded-full overflow-hidden select-none"
                                                      src="/images/vas/english/clorinde.jpg"
                                                      alt="Crystal Lee"/>

                                    <div className="h-2"/>

                                    <div className="flex flex-row gap-1 justify-center">
                                        <Twemoji options={{className: "w-5"}}>🇺🇸</Twemoji>
                                        <h3 className="font-catamaran font-semibold -translate-y-0.5">English</h3>
                                        <Twemoji options={{className: "w-5"}}>🇬🇧</Twemoji>
                                    </div>
                                    <h4 className="font-catamaran">Crsytal Lee</h4>
                                    <div className="flex flex-row gap-2 h-5">
                                        <Link href="https://x.com/CrystalLeeVO">
                                            <SiX className="w-5"/>
                                        </Link>
                                        <Link href="https://www.instagram.com/crystalyleevo/">
                                            <SiInstagram className="w-5"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-start w-36">
                                    <NextImageWrapper className="w-36 h-36 rounded-full overflow-hidden select-none"
                                                      src="/images/vas/japanese/clorinde.jpg"
                                                      alt="Crystal Lee"/>

                                    <div className="h-2"/>

                                    <div className="flex flex-row gap-1 justify-center">
                                        <Twemoji options={{className: "w-5"}}>🇯🇵</Twemoji>
                                        <h3 className="font-catamaran font-semibold -translate-y-0.5">Japanese</h3>
                                        <Twemoji options={{className: "w-5"}}>🇯🇵</Twemoji>
                                    </div>
                                    <h4 className="font-catamaran">Ishikawa Yui</h4>
                                    <div className="flex flex-row gap-2 h-5">
                                        <Link href="https://x.com/YUI_STAFF">
                                            <SiX className="w-5"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-start w-36">
                                    <NextImageWrapper className="w-36 h-36 rounded-full select-none overflow-hidden"
                                                      src="/images/vas/korean/clorinde.png"
                                                      alt="Crystal Lee"/>

                                    <div className="h-2"/>

                                    <div className="flex flex-row gap-1 justify-center">
                                        <Twemoji options={{className: "w-5"}}>🇰🇷</Twemoji>
                                        <h3 className="font-catamaran font-semibold -translate-y-0.5">Korean</h3>
                                        <Twemoji options={{className: "w-5"}}>🇰🇵</Twemoji>
                                    </div>
                                    <h4 className="font-catamaran">Shin Na-ri</h4>
                                    <div className="flex flex-row gap-2 h-5">
                                        <Link href="https://www.youtube.com/@dw11nari">
                                            <SiYoutube className="w-5"/>
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-start w-36">
                                    <div className="w-36 h-36 rounded-full bg-proj-dark"/>

                                    <div className="h-2"/>

                                    <div className="flex flex-row gap-1 justify-center">
                                        <Twemoji options={{className: "w-5"}}>🇨🇳</Twemoji>
                                        <h3 className="font-catamaran font-semibold -translate-y-0.5">Chinese</h3>
                                        <Twemoji options={{className: "w-5"}}>🇨🇳</Twemoji>
                                    </div>
                                    <h4 className="font-catamaran">Zhao Hanyu</h4>
                                    <div className="flex flex-row gap-2 h-5">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="h-16"/>

                <div className="flex justify-center px-16 flex-row gap-5 items-end h-10">
                    <NextImageWrapper alt="Clorinde wish image" src="/images/4-point-star.png"
                                      className="h-10 w-10"/>

                    <div className="rounded-full h-1 flex-grow bg-cyan-500 -translate-y-[1.1rem]"/>

                    <NextImageWrapper alt="Clorinde wish image" src="/images/4-point-star.png"
                                      className="h-10 w-10"/>
                </div>

                <div className="h-16" id="quick-glance"/>

                {/* Section 4 */}
                <div className="flex flex-col h-[calc(100vh-128px)] relative">
                    <Waypoint bottomOffset="70%" onEnter={() => {
                        onSectionChange(2)
                    }}/>
                    <div className="flex flex-row gap-8 h-[calc(100vh-128px)] relative">
                        <div className="flex flex-col flex-grow gap-8 basis-1/2">
                            <div className="flex flex-row flex-grow gap-8 basis-4/6">
                                <div
                                    className="flex flex-col flex-grow rounded-lg bg-proj-box p-4 basis-3/5 gap-4 items-center">
                                    <div className="flex w-full justify-start">
                                        <h1 className="text-2xl font-catamaran font-extrabold">
                                            ASCENSION COST
                                        </h1>
                                    </div>

                                    <div className="flex flex-row gap-4 items-center justify-center flex-grow">
                                        <ItemAmount name="Mora" image="/images/items/mora.png"
                                                    amount={2092530}/>
                                        <ItemAmount name="Hero's Wit" image="/images/items/exp/heros-wit.png"
                                                    amount={419}/>
                                        <ItemAmount name="Lumitoile" image="/images/items/lumitoile.png" amount={168}
                                                    sources={[
                                                        {
                                                            name: "Collection Guide",
                                                            url: "https://www.hoyolab.com/article/21958857",
                                                            icon: <Map/>
                                                        }
                                                    ]}/>
                                        <ItemAmount name="Fontemer Unihorn" image="/images/items/fontemer-unihorn.png"
                                                    amount={46}/>
                                    </div>
                                    <div className="flex flex-row gap-4 items-center justify-center flex-grow">
                                        <ItemAmount name="Vajrada Amethyst Sliver"
                                                    image="/images/items/vajrada-amethyst-sliver.png"
                                                    amount={1}/>
                                        <ItemAmount name="Vajrada Amethyst Fragment"
                                                    image="/images/items/vajrada-amethyst-fragment.png"
                                                    amount={9}/>
                                        <ItemAmount name="Vajrada Amethyst Chunk"
                                                    image="/images/items/vajrada-amethyst-chunk.png"
                                                    amount={9}/>
                                        <ItemAmount name="Vajrada Amethyst Gemstone"
                                                    image="/images/items/vajrada-amethyst-gemstone.png"
                                                    amount={6}/>
                                    </div>
                                    <div className="flex flex-row gap-4 items-center justify-center flex-grow w-full">
                                        <ItemAmountGroup items={[
                                            {
                                                name: "Transoceanic Pearl",
                                                image: "/images/items/transoceanic-pearl.png",
                                                amount: 18
                                            },
                                            {
                                                name: "Transoceanic Chunk",
                                                image: "/images/items/transoceanic-chunk.png",
                                                amount: 68
                                            },
                                            {
                                                name: "Xenochromatic Crystal",
                                                image: "/images/items/xenochromatic-crystal.png",
                                                amount: 93
                                            }
                                        ]} sources={[
                                            {
                                                name: "Collection Guide",
                                                url: "https://www.hoyolab.com/article/28725099",
                                                icon: <Map/>
                                            },
                                            // {
                                            //     name: "Fandom Wiki",
                                            //     url: "https://genshin-impact.fandom.com/wiki/Clorinde",
                                            //     icon: <BookMarked />
                                            // },
                                            // {
                                            //     name: "HoYoWiki",
                                            //     url: "https://wiki.hoyolab.com",
                                            //     icon: <BookOpen />
                                            // }
                                        ]} className="col-span-3 grid-cols-3 w-3/5"/>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-4 flex-grow rounded-lg bg-proj-box p-4 basis-2/5">
                                    <div className="col-span-3 flex">
                                        <h1 className="text-2xl font-catamaran font-extrabold">
                                            TALENT COST
                                        </h1>
                                    </div>

                                    <div className="grid grid-cols-3 grid-rows-3 gap-4 h-full">
                                        <ItemAmount name="Mora" image="/images/items/mora.png"
                                                    amount={4957000} grid={true}/>
                                        <ItemAmount name="Everamber" image="/images/items/everamber.png"
                                                    amount={18} grid={true}/>
                                        <ItemAmount name="Crown of Insight" image="/images/items/crown-of-insight.png"
                                                    amount={3} grid={true}/>
                                        <ItemAmountGroup items={[
                                            {
                                                name: "Transoceanic Pearl",
                                                image: "/images/items/transoceanic-pearl.png",
                                                amount: 18
                                            },
                                            {
                                                name: "Transoceanic Chunk",
                                                image: "/images/items/transoceanic-chunk.png",
                                                amount: 68
                                            },
                                            {
                                                name: "Xenochromatic Crystal",
                                                image: "/images/items/xenochromatic-crystal.png",
                                                amount: 93
                                            }
                                        ]} sources={[
                                            {
                                                name: "Collection Guide",
                                                url: "https://www.hoyolab.com/article/28725099",
                                                icon: <Map/>
                                            },
                                            // {
                                            //     name: "Fandom Wiki",
                                            //     url: "https://genshin-impact.fandom.com/wiki/Clorinde",
                                            //     icon: <BookMarked />
                                            // }
                                        ]} className="col-span-3 grid-cols-3"/>
                                        <div className="flex flex-col items-center justify-center flex-initial">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img alt="Mora" src="/images/items/teachings-of-justice.png"/>
                                            <h4 className="font-catamaran font-bold text-xl">09</h4>
                                        </div>
                                        <div className="flex flex-col items-center justify-center flex-initial">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img alt="Mora" src="/images/items/guide-to-justice.png"/>
                                            <h4 className="font-catamaran font-bold text-xl">63</h4>
                                        </div>
                                        <div className="flex flex-col items-center justify-center flex-initial">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img alt="Mora" src="/images/items/philosophies-of-justice.png"/>
                                            <h4 className="font-catamaran font-bold text-xl">114</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex bg-proj-box rounded-lg p-4 items-center flex-col gap-4 basis-2/6">
                                <div className="flex w-full justify-start">
                                    <h1 className="text-2xl font-catamaran font-extrabold">
                                        POPULAR TEAM COMPOSITION* {/* TODO: Make this a popup */}
                                    </h1>
                                </div>

                                <div className="flex flex-row gap-10 h-full items-center">
                                    <div className="flex flex-col items-center w-16">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img src="/images/chibis/clorinde.png" alt="Clorinde chibi" className="w-16"/>
                                        <h1 className="font-catamaran font-extrabold text-xl">CLORINDE</h1>
                                    </div>
                                    <div className="flex flex-col items-center w-16">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img src="/images/chibis/fischl.png" alt="Clorinde chibi" className="w-16"/>
                                        <h1 className="font-catamaran font-extrabold text-xl">FISCHL</h1>
                                    </div>
                                    <div className="flex flex-col items-center w-16">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img src="/images/chibis/nahida.png" alt="Clorinde chibi" className="w-16"/>
                                        <h1 className="font-catamaran font-extrabold text-xl">NAHIDA</h1>
                                    </div>
                                    <div className="flex flex-col items-center w-16">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img src="/images/chibis/kazuha.png" alt="Clorinde chibi" className="w-16"/>
                                        <h1 className="font-catamaran font-extrabold text-xl">KAZUHA</h1>
                                    </div>
                                </div>

                                <div className="flex w-full justify-start">
                                    <h1 className="text-xs font-catamaran font-extrabold text-neutral-400">
                                        *ACCORDING TO THE SPYRAL ABYSS USAGE (VERSION 4.7)
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col flex-grow gap-4 basis-1/2">
                            <div className="flex flex-row flex-grow gap-8">
                                <div className="flex flex-grow bg-proj-box rounded-lg p-4 items-center flex-col">
                                    <div className="flex w-full justify-start">
                                        <h1 className="text-2xl font-catamaran font-extrabold">
                                            ASCENSION COST
                                        </h1>
                                    </div>

                                    <div className="flex flex-row flex-grow items-center gap-10">
                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/constellations/clorinde/c1.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">C1</h2>
                                        </div>

                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/constellations/clorinde/c2.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">C2</h2>
                                        </div>

                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/constellations/clorinde/c6.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">C6</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-grow bg-proj-box rounded-lg p-4 items-center flex-col">
                                    <div className="flex w-full justify-start">
                                        <h1 className="text-2xl font-catamaran font-extrabold">
                                            ASCENSION COST
                                        </h1>
                                    </div>

                                    <div className="flex flex-row flex-grow items-center">
                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/talents/clorinde/skill.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">SKILL</h2>
                                        </div>

                                        <div className="flex flex-col items-center">
                                            <div className="flex flex-col items-center h-16">
                                                {/* Slightly modified from Feather Icons */}
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                     fill="none" stroke="currentColor" strokeWidth="4"
                                                     className="feather feather-chevron-right w-8">
                                                    <polyline points="9 18 15 12 9 6"/>
                                                </svg>
                                            </div>
                                            <h2 className="text-lg font-catamaran font-extrabold"/> {/* For alignment */}
                                        </div>

                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/talents/clorinde/burst.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">BURST</h2>
                                        </div>

                                        <div className="flex flex-col items-center">
                                            <div className="flex flex-col items-center h-16">
                                                {/* Slightly modified from Feather Icons */}
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                     fill="none" stroke="currentColor" strokeWidth="4"
                                                     className="feather feather-chevron-right w-8">
                                                    <polyline points="7 9 17 9"/>
                                                    <polyline points="7 15 17 15"/>
                                                </svg>
                                            </div>
                                            <h2 className="text-lg font-catamaran font-extrabold"/> {/* For alignment */}
                                        </div>

                                        <div className="flex flex-col gap-2 items-center">
                                            {/* eslint-disable-next-line @next/next/no-img-element */}
                                            <img src="/images/talents/clorinde/normal-attack.png" alt="TODO"
                                                 className="w-12"/>
                                            <h2 className="text-lg font-catamaran font-extrabold">NA</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex bg-proj-box rounded-lg p-4 items-center flex-col gap-4">
                                <div className="flex w-full justify-start">
                                    <h1 className="text-2xl font-catamaran font-extrabold">
                                        WEAPON RECOMMENDATION
                                    </h1>
                                </div>

                                <div className="flex flex-row w-full gap-4">
                                    <TSIDisplay titles={["ABSOLUTION"]} subtitles={["RECOMMENDED"]} images={[{
                                        url: "/images/weapons/absolution2.png",
                                        alt: "Absolution 2nd phase"
                                    }]} sources={[{
                                        name: "Weapon Guide",
                                        url: "https://www.hoyolab.com/article/29707406",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["HARAN GEPPAKU FUTSU"]} subtitles={["ALTERNATIVE"]} images={[{
                                        url: "/images/weapons/haran-geppaku-futsu2.png",
                                        alt: "Haran Geppaku Futsu 2nd phase"
                                    }]} sources={[{
                                        name: "Weapon Guide",
                                        url: "https://www.hoyolab.com/article/23332039",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["THE BLACK SWORD"]} subtitles={["RECOMMENDED"]} images={[{
                                        url: "/images/weapons/the-black-sword2.png",
                                        alt: "The Black Sword 2nd phase"
                                    }]} sources={[{
                                        name: "Weapon Guide",
                                        url: "https://www.hoyolab.com/article/23253850",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["FINALE OF THE DEEP"]} subtitles={["ALTERNATIVE / F2P"]}
                                                images={[{
                                                    url: "/images/weapons/finale-of-the-deep2.png",
                                                    alt: "Finale of the Deep 2nd phase"
                                                }]} sources={[{
                                        name: "Weapon Guide",
                                        url: "https://www.hoyolab.com/article/20939839",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["LION'S ROAR"]} subtitles={["ALTERNATIVE"]} images={[{
                                        url: "/images/weapons/lions-roar2.png",
                                        alt: "Lion's Roar 2nd phase"
                                    }]} sources={[{
                                        name: "Weapon Guide",
                                        url: "https://www.hoyolab.com/article/20719627",
                                        icon: <Map/>
                                    }]}/>
                                </div>
                            </div>
                            <div className="flex bg-proj-box rounded-lg p-4 items-center flex-col gap-4">
                                <div className="flex w-full justify-start">
                                    <h1 className="text-2xl font-catamaran font-extrabold">
                                        ARTIFACT SET RECOMMENDATION
                                    </h1>
                                </div>

                                <div className="flex flex-row w-full gap-4">
                                    <TSIDisplay titles={["FRAGMENT OF HARMONIC WHIMSY"]} subtitles={["RECOMMENDED"]}
                                                images={[{
                                                    url: "/images/artifacts/fragment-of-harmonic-whimsy/flower.png",
                                                    // TODO: Use actual name
                                                    alt: "Fragment of Harmonic Whimsy Flower"
                                                }]} sources={[{
                                        name: "Artifact Guide",
                                        url: "https://www.hoyolab.com/article/27611770",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["GLADIATOR'S FINALE"]} subtitles={["ALTERNATIVE"]} images={[{
                                        url: "/images/artifacts/gladiators-finale/flower.png",
                                        // TODO: Use actual name
                                        alt: "Gladiator's Finale Flower"
                                    }]} sources={[{
                                        name: "Artifact Guide",
                                        url: "https://www.hoyolab.com/article/14078363",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["THUNDERING FURY"]} subtitles={["ALTERNATIVE"]} images={[{
                                        url: "/images/artifacts/thundering-fury/flower.png",
                                        // TODO: Use actual name
                                        alt: "Thundering Fury Flower"
                                    }]} sources={[{
                                        name: "Artifact Guide",
                                        url: "https://www.hoyolab.com/article/6477246",
                                        icon: <Map/>
                                    }]}/>
                                    <TSIDisplay titles={["THUNDERING FURY", "+18% ATK"]} images={[
                                        {
                                            url: "/images/artifacts/thundering-fury/flower.png",
                                            // TODO: Use actual name
                                            alt: "Thundering Fury Flower"
                                        },
                                        {
                                            url: "/images/artifacts/unfinished-reverie/flower.png",
                                            // TODO: Use actual name
                                            alt: "Unfinished Reverie Flower"
                                        }
                                    ]} sources={[
                                        // {
                                        //     name: "Artifact Guide (Thundering Fury)",
                                        //     url: "https://www.hoyolab.com/article/6477246",
                                        //     icon: <Map />
                                        // }
                                    ]}/>
                                </div>
                            </div>
                            <div className="flex bg-proj-box rounded-lg p-4 items-center flex-col">
                                <div className="flex w-full justify-start">
                                    <h1 className="text-2xl font-catamaran font-extrabold">
                                        ARTIFACT STAT RECOMMENDATION
                                    </h1>
                                </div>

                                <div className="flex flex-row w-full gap-4">
                                    <div className="flex flex-1 flex-row items-center gap-2">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img alt="" src="/images/icons/artifacts/sands-of-eon.png"
                                             className="w-16 h-16"/>
                                        <div className="flex flex-col">
                                            <h3 className="font-catamaran font-bold text-sm">ATK%</h3>
                                            <h3 className="font-catamaran font-bold text-sm">EM</h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-row items-center gap-2">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img alt="" src="/images/icons/artifacts/goblet-of-eonothem.png"
                                             className="w-16 h-16"/>
                                        <div className="flex flex-col">
                                            <h3 className="font-catamaran font-bold text-sm">ELECTRO DMG</h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-row items-center gap-2">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img alt="" src="/images/icons/artifacts/circlet-of-logos.png"
                                             className="w-16 h-16"/>
                                        <div className="flex flex-col">
                                            <h3 className="font-catamaran font-bold text-sm">CRIT RATE</h3>
                                            <h3 className="font-catamaran font-bold text-sm">CRIT DMG</h3>
                                        </div>
                                    </div>
                                    <div className="flex flex-1 flex-row items-center gap-2">
                                        {/* eslint-disable-next-line @next/next/no-img-element */}
                                        <img alt="" src="/images/icons/artifacts/artifact.png" className="w-16 h-16"/>
                                        <div className="flex flex-col">
                                            <h3 className="font-catamaran font-bold text-sm">CRIT DMG</h3>
                                            <h3 className="font-catamaran font-bold text-sm">CRIT RATE</h3>
                                            <h3 className="font-catamaran font-bold text-sm">ATK%</h3>
                                            <h3 className="font-catamaran font-bold text-sm">ER</h3>
                                            <h3 className="font-catamaran font-bold text-sm">EM</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}
