import {Source} from "@/types/Source";
import React, {useRef, useState} from "react";
import {
    arrow,
    autoUpdate,
    flip,
    FloatingArrow,
    FloatingFocusManager,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useId,
    useInteractions,
    useRole
} from "@floating-ui/react";

export type ArtifactImage = {
    url: string,
    alt: string
}

export type ArtifactDisplay = {
    titles: string[],
    subtitles?: string[],
    images: ArtifactImage[],
    sources?: Source[]
}

type ArtifactDisplayProps = ArtifactDisplay

/**
 * Title Subtitle Image Display with optional sources
 * @param titles List of full-size titles
 * @param subtitles List of half-size subtitles
 * @param images List of images
 * @param sources List of sources
 */
export default function TSIDisplay({titles, subtitles, images, sources}: ArtifactDisplayProps) {
    const [isOpen, setIsOpen] = useState(false)
    const headingId = useId();
    const arrowRef = useRef(null);
    const {refs, floatingStyles, context} = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        middleware: [
            arrow({element: arrowRef}),
            offset(10),
            flip({fallbackAxisSideDirection: "end"}),
            shift(),
        ],
        whileElementsMounted: autoUpdate,
        placement: "top"
    });

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);
    const {getReferenceProps, getFloatingProps} = useInteractions([
        click,
        dismiss,
        role
    ]);

    return (
        <div
            className={`flex flex-col items-center flex-1 ${(sources && sources.length > 0) ? "hover:bg-proj-dark rounded-lg transition-all cursor-pointer" : ""}`}
            ref={refs.setReference} {...getReferenceProps()}>
            <div className="flex flex-row">
                {images.map((image, index) => {
                    return (
                        /* eslint-disable-next-line @next/next/no-img-element */
                        <img alt={image.alt} src={image.url} className="w-16" key={index}/>
                    )
                })}
            </div>

            {titles.map((title, index) => {
                return (
                    <h3 className="font-catamaran font-extrabold text-center" key={index}>{title}</h3>
                )
            })}

            {subtitles?.map((subtitle, index) => {
                return (
                    <h2 className="text-xs font-catamaran font-semibold" key={index}>{subtitle}</h2>
                )
            })}

            {(isOpen && sources && sources.length > 0) && <FloatingFocusManager context={context} modal={false}>
				<div
					className="Popover bg-proj-dark rounded-lg no-focus-outline p-4 focus:outline-none flex flex-col"
					ref={refs.setFloating}
					style={floatingStyles}
					aria-labelledby={headingId}
                    {...getFloatingProps()}
				>
					<FloatingArrow context={context} ref={arrowRef}
								   className="[&>path:first-of-type]:stroke-proj-dark [&>path:last-of-type]:stroke-proj-dark fill-proj-dark"/>
                    {sources?.map((source, index) => {
                        return (
                            <a className="flex flex-row gap-2 items-center focus:outline-none"
                               href={source.url} target="_blank" key={index}>
                                {source.icon}
                                <h2 id={headingId}
                                    className="text-xl font-catamaran font-bold underline underline-offset-2">
                                    {source.name}
                                </h2>
                            </a>
                        )
                    })}
				</div>
			</FloatingFocusManager>}
        </div>
    );
}
